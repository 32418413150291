import React from "react"

import styled from 'styled-components'
import { ButtonLink } from '../Buttons'
import { Link } from "gatsby"

import portfolioImage1 from '../../static/images/home_portfolio_1.jpg';
import portfolioImage2 from '../../static/images/home_portfolio_2.jpg';
import portfolioImage3 from '../../static/images/home_portfolio_3.jpg';

import categoryList from '../../config/portfolioCategories';

const PortfolioWrapper = styled.div`
    padding: 4rem 0;

    ${({ theme }) => theme.media.md`
        padding: 7.5rem 0;
   `}
    background-color: ${props => props.theme.colors.bg_secondary};
    color: #fff;
`

const PortfolioCategory = styled(Link).attrs({
    className: 'd-flex flex-column align-items-center'
})`
    width: 100%;
    color: #fff;
    background: ${props => props.theme.colors.secondary} center no-repeat;
    padding: 8.75rem 2rem;

    ${props => props.image && `
        background-size: cover;
        background-image: url(${props.image});
    `}
`

const Portfolio = () => (
    <>
        <PortfolioWrapper>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 text-center">
                        <h2 className="subtitle mb-5">Portfolio</h2>
                        <h3 className="hero-text mb-5">Ať už chcete potěšit své blízké nebo sami sebe, jsem tu pro Vás.</h3>
                        <ButtonLink to="/portfolio">Prohlédnout celou galerii</ButtonLink>
                    </div>
                </div>
            </div>
        </PortfolioWrapper>

        <div className="d-flex flex-column text-center flex-lg-row">
            <PortfolioCategory image={portfolioImage1} to="/portfolio" state={{ category: categoryList[1] }}>
                <h3 className="hero-text">Svatby</h3>
                <span className="subtitle">moje srdeční záležitost</span>
            </PortfolioCategory>
            <PortfolioCategory image={portfolioImage2} to="/portfolio" state={{ category: categoryList[2] }}>
                <h3 className="hero-text">Společenské události</h3>
                <span className="subtitle">plesy, konference, oslavy jubilea</span>
            </PortfolioCategory>
            <PortfolioCategory image={portfolioImage3} to="/portfolio" state={{ category: categoryList[3] }}>
                <h3 className="hero-text">Kytice</h3>
                <span className="subtitle">narozeniny, promoce, žádost o ruku</span>
            </PortfolioCategory>
        </div>
    </>
)

export default Portfolio
