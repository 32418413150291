import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import {
  Intro,
  Portfolio,
  About,
} from '../components/home'

const IndexPage = () => (
  <Layout>
    <SEO title="Květinový ateliér" />
    <Intro />
    <Portfolio />
    <About />
  </Layout>
)

export default IndexPage
