import React from "react"

import styled from 'styled-components'

const IntroWrapper = styled.div`
    padding: 4rem 0;

    ${({ theme }) => theme.media.md`
        padding: 7.5rem 0;
   `}
`;

const Intro = () => (
    <IntroWrapper>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8 text-center">
                    <h2 className="subtitle mb-5">Všude kytky</h2>
                    <h3 className="hero-text mb-5">Práce s květinami mě neuvěřitelně naplňuje. Přistupuji k nim s láskou, což se odvíjí v mé práci. </h3>
                    <p className="mb-0">Preferuji tradiční hodnoty, proto si velkou část květin sama pěstuji v drsných podmínkách Jeseníků. Mám ráda, když květiny vychází z přírody. Proto jsou mé květiny tak trochu divoké.. A jsou všude!</p>
                </div>
            </div>
        </div>
    </IntroWrapper>
)

export default Intro
